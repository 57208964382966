import RechargeFooter from "components/Footers/RechargeFooter.js";
import RechargeTalksNavbar from 'components/Navbars/RechargeTalksNavbar';
import React from "react";
import { Helmet } from 'react-helmet';
import { domain, scheme } from '../../constants';
import Recharge2014 from "./recharge2014";
import Recharge2018 from "./recharge2018";
import Recharge2022 from "./recharge2022";
import Recharge2023 from "./recharge2023";
import SmallerHeader from './SmallerHeader';

const title = 'The Recharge Conference Talks, talks from previous conferences';
const pageUrl = `${scheme}${domain}/recharge/talks`; 

function RechargeTalks() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
      document.body.classList.add("index");
      return function cleanup() {
        document.body.classList.remove("index");
      };
    });
    return (
      <>
        <Helmet>
          <meta name='title' content={title} />
          <meta property="og:title" content={title} />
          <link rel='canonical' href={pageUrl} />
          <meta property="og:url" content={pageUrl} />
        </Helmet>
        <RechargeTalksNavbar />
        <SmallerHeader />
        <div className="main">
          <Recharge2023 />
          <Recharge2022 /> 
          <Recharge2018 /> 
          <Recharge2014 />         
          <RechargeFooter />
        </div>
      </>
    );
  }
  
  export default RechargeTalks;