/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Container, Row, Col } from "reactstrap";
import ReactPlayer  from 'react-player';

function Renew2021() {
  return (
    <>
      <div id="2021" className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">2021</h1>
              <div className="card-avatar">
                <img
                  alt="Renew 2021"
                  className="img-rounded img-responsive"
                  src={
                    require("assets/img/renew_conference/talks/2021/Nehemiah.jpg")
                    .default
                  }
                />
              </div>
              <p className="p-larger pb-2 pt-5">
                The theme of our 2021 conference was <stong>Rebuilding - talks from Nehemiah</stong>. <br /><br />
                Kara Hartley took us through this great Old Testament book. Jerusalem has been destroyed and the Israelites and scattered, it is here that Nehemiah enters in the hope to rebuild the city and bring God's people back to His word.
              </p>

              <div className="card-avatar">
                <ReactPlayer
                  className="img-rounded img-responsive"
                  url="https://youtu.be/jG7jNA8tV-g"
                  controls={true}
                />
                <ReactPlayer 
                  className="img-rounded img-responsive pt-5"
                  url="https://youtu.be/LpDyDtahYuU"
                  controls={true}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Renew2021;
