/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Col, Container, Row } from "reactstrap";
import CustomAudioPlayer from '../../../components/Utils/CustomAudioPlayer';

function Recharge2014() {
  return (
    <>
      <div id="2014" className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">2014</h1>
              <p className="p-larger description pb-2 pt-5">
                Our 2014 Conference saw 140 men gather to hear Bruce Bennett from Orange Evangelical Church share <strong>Action stations - God's plan for men</strong> as expressed in Ephesians.
              </p>

              <CustomAudioPlayer 
                title="Talk 1: God's Battle Plan"
                speaker="Bruce Bennett"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/recharge/2014/Recharge_2014_Talk_1_Gods_Battle_Plan.mp3"
                filename="Recharge_2014_Talk_1_Gods_Battle_Plan.mp3"
              />
              <CustomAudioPlayer 
                title="Talk 2: Our Place in God's Battle"
                speaker="Bruce Bennett"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/recharge/2014/Recharge_2014_Talk_2_Our_Place_in_Gods_Battle.mp3"
                filename="Recharge_2014_Talk_2_Our_Place_in_Gods_Battle.mp3"
              />
              <CustomAudioPlayer 
                title="Talk 3: Stand Together and Fight"
                speaker="Bruce Bennett"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/recharge/2014/Recharge_2014_Talk_3_Stand_Together_and_Fight.mp3"
                filename="Recharge_2014_Talk_3_Stand_Together_and_Fight.mp3"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Recharge2014;
