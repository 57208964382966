/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Col, Container, Row } from "reactstrap";
import CustomAudioPlayer from '../../../components/Utils/CustomAudioPlayer';

function Renew2015() {
  return (
    <>
      <div id="2015" className="section section-dark">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">2015</h1>
              <p className="p-larger description pb-2 pt-5">
                The theme of this years conference was <strong>Rest Assured</strong>.<br /><br />
                Our key note speaker in 2015 was Claire Smith. Cathy Young helped us think more practically about the nature of rest and what prevents us from having the rest God offers to us.
              </p>

              <CustomAudioPlayer 
                title="Talk 1"
                speaker="Cathy Young"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2015/Renew_2015_Talk_1.mp3"
                filename="Renew_2015_Talk_1.mp3"
              />
              <CustomAudioPlayer 
                title="Talk 2"
                speaker="Cathy Young"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2015/Renew_2015_Talk_2.mp3"
                filename="Renew_2015_Talk_2.mp3"
              />
              <CustomAudioPlayer 
                title="Talk 3"
                speaker="Cathy Young"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2015/Renew_2015_Talk_3.mp3"
                filename="Renew_2015_Talk_3.mp3"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Renew2015;
