/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Container, Row, Col } from "reactstrap";

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/estuary_web.jpg").default + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center">
                <h1 className="presentation-title">Hunter Gospel Ministries</h1>
                <h2 className="presentation-subtitle text-center">
                  Working co-operatively to advance Christ's Kingdom
                </h2>
              </Col>
            </Row>              
          </Container>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
