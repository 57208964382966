import React from "react";
import { Alert, Card, Container, Button, Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Reaptcha from 'reaptcha';

export default class Connect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      email: false, 
      verified: false, 
      isSent:false,
      isError: false 
    };
  }

  onVerify = recaptchaResponse => {
    if (recaptchaResponse) {
      this.setState({
        verified: true
      });
    }
  };

  handleValidSubmit = (event, values) => {
    event.preventDefault(); 

    this.setState({ 
      email: values.email,
      name: values.name,
      phone: values.phone,
      message: values.message
    });
    
	  const body = JSON.stringify(
      {
      senderName: values.name,
      senderPhone: values.phone,
      senderEmail: values.email,
      senderMessage: values.message,
      source: 'hgm'
      }
    );
  
    fetch('https://xhbf608izh.execute-api.ap-southeast-2.amazonaws.com/default/SendWebformEmail', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body
    }).then((response) => {
      console.log("Message sent successfully!", response);
      this.setState({
        isSent: true
      });
    }).catch((error) => {
      console.log('An unknown error occured', error);
      this.setState({
        isError: true
      }); 
    });  
  };  

  handleInvalidSubmit = (event, errors, values) => {
    this.setState({ email: values.email, error: true });
    console.log(`Failed:`, errors);
  };

  render() { 
    return (
      <>
        <div
          id="connect"
          className="page-header"
          style={{
            backgroundImage:
              "url(" + require("assets/img/vineyards_web.jpg").default + ")",
          }}
        >
          <div className="filter" />
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h1 className="title motto text-center">Connect</h1>
              </Col>
            </Row>    
            <Row>
              <Col className="hgm-current ml-auto mr-auto" lg="4">
                <Card className="card-register ml-auto mr-auto">
                  <AvForm
                    onValidSubmit={this.handleValidSubmit}
                    onInvalidSubmit={this.handleInvalidSubmit}
                  >
                    <AvField
                      name="name"
                      label="Your name"
                      placeholder="Your name"
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter your name"
                        }
                      }}
                    />

                    <AvField
                      name="phone"
                      label="Your phone number"
                      placeholder="Your phone number"
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter your phone number"
                        },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage:
                            "Your phone number may only contain numbers"
                        },
                      }}
                    />
                    
                    <AvField
                      name="email"
                      label="Your email"
                      placeholder="Your email"
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter your email"
                        },
                        email: {
                          value: true,
                          errorMessage: "Please enter a valid email"
                        }
                      }}
                    />

                    <AvField
                      name="message"
                      label="Your message"
                      placeholder="Your message"
                      type="textarea"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter your message"
                        }
                      }}
                    />

                  <Reaptcha sitekey="6Le7qXYeAAAAAO0OboHr4vPVB0LPlEJV54o1Hq2s" onVerify={this.onVerify} />

                  <Button id="submit" block className="btn-round btn-hgm-current" disabled={!this.state.verified}>Submit</Button>

                  </AvForm>

                  {this.state.isSent ? 
                      <Alert color="info alert-wrapper">
                        <Container>
                          <span>Thanks, your message has been sent!</span>
                        </Container>
                      </Alert> 
                    : null }

                    {this.state.isError ? 
                      <Alert color="danger alert-wrapper">
                        <Container>
                          <span>Sorry, there was a problem, your message has not  been sent.</span>
                        </Container>
                      </Alert> 
                    : null }
                  
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
  }
}