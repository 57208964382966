import React from "react";
import { Col, Container, Row } from "reactstrap";

function SmallerHeader() {
  return (
    <>
      <div className="section section-renew-pink">
        <Container>
          <Row xs="4" className="pt-5">
            <Col className="ml-auto mr-auto text-center">
              <div className="title-brand">
                <img
                  alt="Renew Logo"
                  className="img-rounded img-responsive renew-logo"
                  src={require("assets/img/renew_conference/logos/Renew_logo_grey.svg").default}
                ></img>
              </div>
                
            </Col>
          </Row>              
          <Row>
            <Col className="ml-auto mr-auto text-center">
              <h1 className="title">Renew Conference Talks</h1>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SmallerHeader;