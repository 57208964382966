/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Container, Row, Col } from "reactstrap";


function About() {
  return (
    <>
      <div id="about" className="section section-dark">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">About</h1>
              <p className="p-larger description pb-2">
                Resource is a great opportunity to become more skilled in the use of one of God's powerful weapons.  
                <br/><br/>
                Resource Conference usually happens in November each year. 
                <br/><br/>
                The workshop runs over two days. Each year we have addressed a different aspect of Bible teaching and preaching. 
                <br/><br/>
                We have five basic aims:
                <br/><br/>
                To <strong>re-kindle an excitement</strong> with respect to God's living word, and the power of that word for salvation, recognising that many pastors and bible teachers often lose that excitement.
                <br/><br/>
                To delegates to be <strong>excited about preaching and teaching</strong> 'the whole counsel of God' as the 'mainstay' of their ministry.
                <br/><br/>
                To <strong>encourage and assist</strong> delegates to be better (more faithful and engaging) preachers and teachers of each of the various parts of God's word, as well as various topical themes.
                <br/><br/>
                To send delegates away with <strong>'the bones of' a sermon or teaching series</strong> they can work through in their local church. This ensures dividends on the time investment of two days - both practical engagement on the day, and also benefit in programming subsequent preaching or teaching series.
                <br/><br/>
                To <strong>foster a network of friends and supporters</strong> in the Hunter region - i.e. develop more of a we're-on-the-same-team approach to Christ's work in the Hunter. The 'workshop' approach has been chosen intentionally to maintain a personal feel, as opposed to a lecture style.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default About;
