/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Col, Container, NavLink, Row } from "reactstrap"; // , Button,  NavLink 


function CurrentConference() {

  // let pageHeader = React.createRef();

  // React.useEffect(() => {
  //   if (window.innerWidth < 991) {
  //     const updateScroll = () => {
  //       let windowScrollTop = window.pageYOffset / 3;
  //       pageHeader.current.style.transform =
  //         "translate3d(0," + windowScrollTop + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // });

  return (
    <>
      <div id="currentConference">
        {/* <div
          className="page-header"
          data-parallax={false}
          style={{
          backgroundImage:
            "url(" + require("assets/img/renew_conference/heros/2022/Hero_2022.jpg").default + ")",
          }}
          ref={pageHeader}
        > 
        </div>*/}
        <div className="section section-dark">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                {/* <h1 className="title-brand">Saturday 25th May 2024</h1> */}

                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/renew_conference/2024/Postcard_Front_small.png").default}
                />

                <p className="p-larger description pt-5 pb-2">
                  Renew24 tackled the topic of Relationships! All manner of relationships in fact - marriage, singleness, divorce, widowed. 
                </p>

                <p className="p-larger description pt-5 pb-2">
                  With the noise of popular culture ringing in our ears, it's great to stay grounded in God's good plans as His people learn to live out His ideals in a relationally challenged world.
                </p>

                <p className="p-larger description pt-5 pb-2">
                  You can listen to and download the Renew24 talks now. <NavLink href="/renew/talks#2024" title="Renew 2024 Talks" className="href-white">Renew24 talks</NavLink> 
                </p>

                {/* <Button color="warning" size="lg" type="button" target="_blank" href="https://www.eventcreate.com/e/renew-2024" rel="noreferrer" >
                  Register Now
                </Button> */}
                
                {/* <h2 className="title">Speaker</h2>
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/renew_conference/profiles/Dani_Treweek_smaller.png").default}
                />
                <p className="p-larger description">Dani Treweek</p>
                <br/>
                <p className="p-larger description pt-5 pb-2">
                  Our guest speaker is Rev Dr Dani Treweek, who has just released a book (based on her PhD research) called 'The Meaning of Singleness.' She will be unpacking the Bible's teaching on God's intention for all our relationships. 
                </p>

                <p className="p-larger description pt-5 pb-2">
                  You can find out more about Dani here: <a className="href-white" href="https://www.danielletreweek.com/" target="_blank" rel="noreferrer">www.danielletreweek.com</a> 
                </p> */}
               
                {/* <Button color="warning" size="lg" type="button" target="_blank" href="https://www.eventcreate.com/e/renew-2024" rel="noreferrer" >
                  Register Now
                </Button> */}

                {/* <h2 className="title">Speaker</h2>
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/renew_conference/profiles/Michelle_Underwood_2023.jpg").default}
                />
                <p className="p-larger description">Michelle Underwood</p>
                <br/>
                <p className="p-larger description ">
                  Michelle lives in Perth, although was born in Fiji and grew up in Sydney. 
                  She has degrees in psychology, theology and teaching, and would really like to finish one in economics. 
                </p>
                <p className="p-larger description ">
                  She is married to the sexy rector of St Edmund's Anglican, Wembley and has 2 lovely teenage kids. She spends a lot of time with teenagers actually in her job as a Biblical Studies teacher at Peter Moyes Anglican Community School. 
                </p>
                <p className="p-larger description ">
                  One day she'd like to work at Officeworks because she really thinks good stationery can make a difference in people's lives.
                </p>

                */}
{/*                 
                <h2 className="title">Details</h2>
                <p className="p-larger description ">
                  <strong>Saturday 25th May 2024</strong>
                </p>
                <p className="p-larger description">
                  Doors open 8:45am
                </p>
                <p className="p-larger description">
                  Metford Community Baptist Church<br/> 
                  <a className="href-white" href="https://www.google.com/maps/place/83-85+Chelmsford+Dr,+Metford+NSW+2323/@-32.7665239,151.6066012,17z/data=!3m1!4b1!4m5!3m4!1s0x6b73414ce6ead803:0x9a597b828d8472de!8m2!3d-32.7665239!4d151.6087899" target="_blank" rel="noreferrer">83-85 Chelmsford Drive, Metford NSW</a><br/>
                </p>

                <h2 className="title">Registrations</h2>

                <p className="p-larger description">
                  Get in early! A beautiful morning tea and lunch is included. 
                </p>

                <br />

                <p className="p-larger description">
                  $40 Early bird/Concession and Pensioners (Ends 25th April)
                </p>
                
                <p className="p-larger description">
                  $50 Regular (From 26th April)
                </p>

                <p className="p-larger description">
                  $30 High School Students
                </p>

                <br />
                
                <p className="p-larger description">
                  Registrations close 15th May
                </p>

                <p className="p-larger description">
                  For cheque payments and all enquiries please: <NavLink href="#connect" title="Connect" className="href-white">Connect with us</NavLink>
                </p>

                <br />
                <br />

                <Button color="warning" size="lg" type="button" target="_blank" href="https://www.eventcreate.com/e/renew-2024" rel="noreferrer" >
                  Register Now
                </Button> */}
                
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default CurrentConference;
