/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Container, Row, Col } from "reactstrap";
import CustomAudioPlayer from '../../../components/Utils/CustomAudioPlayer';

function Renew2016() {
  return (
    <>
      <div id="2016" className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">2016</h1>
              <p className="p-larger pb-2 pt-5">
                The theme of our 2016 conference was <stong>Joy in Jesus</stong>. <br /><br />
                Sue Harrington lead us through the Letter to the Philippians - a great book of joy and hope!
              </p>

              <CustomAudioPlayer 
                title="Talk 1: Joy as you put Jesus first"
                speaker="Sue Harrington"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2016/Renew_2016_Talk_1.mp3"
                filename="Renew_2016_Talk_1_Joy_as_you_put_Jesus_first.mp3"
              />
              <CustomAudioPlayer 
                title="Talk 2: Joy as you follow / lead others in living for Jesus"
                speaker="Sue Harrington"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2016/Renew_2016_Talk_2.mp3"
                filename="Renew_2016_Talk_2_Joy_as_you_follow_lead_others_in_living_for_Jesus.mp3"
              />
              <CustomAudioPlayer 
                title="Talk 3: Joy as you stand firm and press on in Jesus"
                speaker="Sue Harrington"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2016/Renew_2016_Talk_3.mp3"
                filename="Renew_2016_Talk_3_Joy_as_you_stand_firm_and_press_on_in_Jesus.mp3"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Renew2016;
