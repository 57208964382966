import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss';
import { Button } from "reactstrap";

function CustomAudioPlayer(props) {
  const {title, speaker, url, filename} = props;

  return (
    <>
      <h4 className='title'>{title} - {speaker}</h4>
      <AudioPlayer
        autoPlay={false}
        loop={false}
        layout="horizontal-reverse"
        src={url}
      />
      <br/>
      <a href={url} download={filename}>
        <Button className="btn-round">Download</Button>
      </a>
    </>
  );
}

export default CustomAudioPlayer;