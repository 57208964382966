/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Col, Container, Row } from "reactstrap";
import CustomAudioPlayer from '../../../components/Utils/CustomAudioPlayer';

function Renew2019() {
  return (
    <>
      <div id="2019" className="section section-dark">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">2019</h1>
              <div className="card-avatar">
                <img
                  alt="Renew 2019"
                  className="img-rounded img-responsive"
                  src={
                    require("assets/img/renew_conference/talks/2019/Prayer.jpg")
                    .default
                  }
                />
              </div>
              <p className="p-larger description pb-2 pt-5">
                The theme of this years conference was <strong>Prayer; How, What and Why?</strong>.<br /><br />
                Our speaker was Susan Ravenhall. Susan loved thinking long and hard about the Lord's Prayer - the how, what and why of prayer.
              </p>

              <CustomAudioPlayer 
                title="Talk 1"
                speaker="Susan Ravenhall"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2019/Renew_2019_Talk_1.mp3"
                filename="Renew_2019_Talk_1.mp3"
              />
              <CustomAudioPlayer 
                title="Talk 2"
                speaker="Susan Ravenhall"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2019/Renew_2019_Talk_2.mp3"
                filename="Renew_2019_Talk_2.mp3"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Renew2019;
