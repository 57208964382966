/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Container, Row, Col } from "reactstrap";
import CustomAudioPlayer from '../../../components/Utils/CustomAudioPlayer';

function Renew2023() {
  return (
    <>
      <div id="2023" className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">2023</h1>
              <div className="card-avatar">
                <img
                  alt="Renew 2023"
                  className="img-rounded img-responsive"
                  src={require("assets/img/renew_conference/2023/Renew_banner.jpg").default}
                />
              </div>

              <p className="p-larger pt-5 pb-2">
                The theme of our 2023 conference was <stong>'The Unexpected Jesus'</stong>. Michelle Underwood was the speaker. <br /><br />
                Getting to truly know someone is a wonderful journey that takes a lifetime. No matter how long you've known each other, there are still things that take you by surprise and remind you there is always more to discover.
              </p>   

              <CustomAudioPlayer 
                title="Talk 1"
                speaker="Michelle Underwood"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2023/Renew_2023_Talk_1.mp3"
                filename="Renew_2023_Talk_1.mp3"
              />            

              {/* <div className="card-avatar">
                <ReactPlayer
                  className="img-rounded img-responsive"
                  url="https://youtu.be/jG7jNA8tV-g"
                  controls={true}
                />
                <ReactPlayer 
                  className="img-rounded img-responsive pt-5"
                  url="https://youtu.be/LpDyDtahYuU"
                  controls={true}
                />
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Renew2023;
