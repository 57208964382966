import ResourceFooter from "components/Footers/ResourceFooter.js";
import React from "react";
import { Helmet } from 'react-helmet';
import About from "views/resource-sections/About.js";
import Connect from "views/resource-sections/Connect.js";
import CurrentConference from "views/resource-sections/CurrentConference";
import PastSpeakers from "views/resource-sections/PastSpeakers.js";
import ResourceHeader from '../components/Headers/ResourceHeader';
import ResourceNavbar from '../components/Navbars/ResourceNavbar';
import { domain, scheme } from './constants';

const title = 'The Resource Conference, for Bible teachers in the Hunter Valley';
const pageUrl = `${scheme}${domain}/resource`; 

function Resource() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
      document.body.classList.add("index");
      return function cleanup() {
        document.body.classList.remove("index");
      };
    });
    return (
      <>
        <Helmet>
          <meta name='title' content={title} />
          <meta property="og:title" content={title} />
          <link rel='canonical' href={pageUrl} />
          <meta property="og:url" content={pageUrl} />
        </Helmet>
        <ResourceNavbar />
        <ResourceHeader />
        <div className="main">
          <CurrentConference />
          <About />
          <PastSpeakers />
          <Connect />
          <ResourceFooter />
        </div>
      </>
    );
  }
  
  export default Resource;