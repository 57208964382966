import RenewFooter from "components/Footers/RechargeFooter.js";
import RenewTalksNavbar from 'components/Navbars/RenewTalksNavbar';
import React from "react";
import { Helmet } from 'react-helmet';
import { domain, scheme } from '../../constants';
import Renew2010 from "./renew2010";
import Renew2012 from "./renew2012";
import Renew2014 from "./renew2014";
import Renew2015 from "./renew2015";
import Renew2016 from "./renew2016";
import Renew2019 from "./renew2019";
import Renew2021 from "./renew2021";
import Renew2022 from "./renew2022";
import Renew2023 from "./renew2023";
import Renew2024 from "./renew2024";
import SmallerHeader from './SmallerHeader';


const title = 'The Renew Conference Talks, talks from previous conferences';
const pageUrl = `${scheme}${domain}/renew/talks`; 

function RechargeTalks() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
      document.body.classList.add("index");
      return function cleanup() {
        document.body.classList.remove("index");
      };
    });
    return (
      <>
        <Helmet>
          <meta name='title' content={title} />
          <meta property="og:title" content={title} />
          <link rel='canonical' href={pageUrl} />
          <meta property="og:url" content={pageUrl} />
        </Helmet>
        <RenewTalksNavbar />
        <SmallerHeader />
        <div className="main">
          <Renew2024 />
          <Renew2023 />
          <Renew2022 />
          <Renew2021 />
          <Renew2019 />
          <Renew2016 />
          <Renew2015 />
          <Renew2014 />
          <Renew2012 />
          <Renew2010 />          
          <RenewFooter />
        </div>
      </>
    );
  }
  
  export default RechargeTalks;