import ReactPlayer from 'react-player';
import { Col, Container, Row } from "reactstrap"; 

function CurrentConference() {

  return (
    <>
      <div id="currentConference" className="section section-dark">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title-brand">Spiritual Power</h1>
              <h3 className="title-brand">How does the Cross reinvent where power is found?</h3>

              <p className="p-larger description pt-5 pb-2">
                Our Speaker: Martin Shadwick <br /><br />

                Martin Shadwick is head of the mission group AFES at University of Newcastle, bringing together students from churches all over the hunter. For three years he has joined the committee for 'Uphold', where he has been supporting Christians mistreated by religious organisations. Uphold exists to confront the epidemic of abuse and mistreatment of gospel workers in Christian churches and organisations. Through this, Martin has a unique insight into the power of the Cross. <br/><br/>
                You can find more about Uphold here: <a target="_blank" rel="noreferrer" href="https://www.uphold.org.au/">https://www.uphold.org.au/</a>
              </p>
              <br />

              {/* <Button color="warning" size="lg" type="button" target="_blank" href="https://www.eventcreate.com/e/recharge-24" rel="noreferrer" >
                Register Now
              </Button> */}

              <p className="p-larger description pt-5 pb-2">
                September 14th <br />
                8.15am Coffee & Registration<br />
                9am Start to 3pm Finish<br />
                Grace Evangelical Church, Glendale<br/> 
                <a href="https://www.google.com/maps/place/Grace+Evangelical+Church+Newcastle+Inc./@-32.928268,151.6407383,17z/data=!3m1!4b1!4m5!3m4!1s0x6b733c25d420430b:0x2b393a69344dbaf9!8m2!3d-32.9282725!4d151.642927" target="_blank" rel="noreferrer">344-348 Lake Rd, Glendale, NSW, 2285, Australia</a>
              </p>

              <br />
              <br />

              <ReactPlayer
                className="ml-auto mr-auto text-center img-rounded img-responsive"
                url="https://youtu.be/SQey1gaI3YQ"
                controls={true}
              />

              <p className="p-larger description pt-5 pb-2">You can download this promotional interview video from the <a href="/#promotions">Promotions section</a>.</p>


              {/* <Button color="warning" size="lg" type="button" target="_blank" href="https://www.eventcreate.com/e/recharge-24" rel="noreferrer" >
                Register Now
              </Button> */}

              {/* <h1 className="title-brand">Recharge 2024 - September 14th</h1> */}

              {/* <p className="p-larger description pt-5 pb-2">
                Lock in and share the date with your fellow brothers. 
                <br/><br/>
                See you all again in 2024!
              </p> */}
              

              {/* <ReactPlayer
                className="ml-auto mr-auto text-center img-rounded img-responsive"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/recharge/2023/Recharge-2023-Interview.mp4"
                controls={true}
              /> */}
             
              {/* <h2 className="title">Speaker</h2>
              <img
                alt="..."
                className="img-rounded img-responsive"
                src={require("assets/img/recharge_conference/profiles/Neville_Naden_2023.jpg").default}
              />
              <p className="p-larger description">Neville Naden</p>
              

              {/* <h2 className="title">Details</h2>
              <p className="p-larger description">
                <strong>'A Reconciliation that works'</strong><br/>
                Saturday 9th September 2023<br/><br/>
                Grace Evangelical Church<br/> 
                <a className="btn-link" href="https://www.google.com/maps/place/Grace+Evangelical+Church+Newcastle+Inc./@-32.928268,151.6407383,17z/data=!3m1!4b1!4m5!3m4!1s0x6b733c25d420430b:0x2b393a69344dbaf9!8m2!3d-32.9282725!4d151.642927" target="_blank" rel="noreferrer">344-348 Lake Rd, Glendale, NSW, 2285, Australia</a><br/>
                8:30am to 3:00pm<br/>
                <br/>
                
                <em>Doors open from 8:30am</em><br/>
                <br/>
                General admissions registrations have now closed. We would still love you to come! We've ordered food and other items for a few extras, but will run out with an influx of people.
                <br/>
                <br/>
                If you would still like to attend please contact us at <a href="mailto: info@hgm.org.au">info@hgm.org.au</a> when you submit a late admission below.<br/>
                <br/>
                {/* <Button color="warning" size="lg" type="button" target="_blank" href="https://www.eventcreate.com/e/recharge23" rel="noreferrer" >
                  Register Now
                </Button>
            </p> */}
            </Col> 
          </Row>
        </Container>
      </div>
</>)
};

export default CurrentConference;