/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Container, Row, Col } from "reactstrap";
import ReactPlayer  from 'react-player';

function Recharge2023() {
  return (
    <>
      <div id="2023" className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">2023</h1>
              <p className="p-larger pb-2 pt-5">
                The theme of our 2023 conference was <stong>True Reconciliation</stong>. 
                <br /><br />
                Neville Naden opened up God's Word about the only true reconciliation we can know.
              </p>

              <div className="card-avatar">
                <ReactPlayer
                  className="img-rounded img-responsive"
                  url="https://www.youtube.com/watch?v=QyU1L5LHCG8"
                  controls={true}
                />
                <ReactPlayer 
                  className="img-rounded img-responsive pt-5"
                  url="https://www.youtube.com/watch?v=OUIPg15N-kM"
                  controls={true}
                />
                <ReactPlayer 
                  className="img-rounded img-responsive pt-5"
                  url="https://www.youtube.com/watch?v=FMo_QlXDyTY"
                  controls={true}
                />

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Recharge2023;
