/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Container, Row, Col } from "reactstrap";

function About() {
  return (
    <>
      <div id="about" className="section section-dark">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">About</h1>
              <p className="p-larger description">
                Hunter Gospel Ministries grew out of an evangelism training day in Newcastle in 2002, jointly organised by three local churches with our common vision. This vision has continued well over 20 years, through three specific events annually:
                <br /><br />

                <strong>Renew</strong> - A day conference for women <br/>(previously called Women of Truth ). <br/><br/>
                <strong>Recharge</strong> - A day conference for men <br/>(previously called Hunter Men's Convention).<br/><br/>
                <strong>Resource</strong> - A two-day conference for men and women engaged in teaching the Bible in their local church <br/>(previously called Bible Teacher's Workshop).<br/><br/>
      
                Over the years the organising committee, though still small for practical reasons, has grown to represent a much wider support base of local churches. Likewise, each year has seen more people 'getting on board' from a more diverse range of local churches.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default About;
