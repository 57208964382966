import { Button } from "reactstrap";

function Resource(props) {
  const {title, url, filename} = props;

  return (
    <>
      <h4 className='title'>{title}</h4>
      <a href={url} download={filename}>
        <Button className="btn-round">Download</Button>
      </a>
    </>
  );
}

export default Resource;