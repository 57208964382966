/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ReactPlayer from 'react-player';
import React from "react";
import { Container, Row, Col } from "reactstrap";

function About() {
  return (
    <>
      <div id="about" className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">About</h1>
              <p className="p-larger pb-2">
                'Recharge' is an old, and proven event, formally known as Hunter Men's Convention, but with a new name.   
                <br/><br/>
                The idea of 'recharging' impacts us at every point in life - phones, power tools, computers, and cars. One dictionary definition puts it like this: “To restore electrical energy in (a battery or a battery-operated device) by connecting it to a power supply.” It is not an overstatement to say that daily life could not continue without the practicality of 'recharging'.
                <br/><br/>
                In a nutshell, our recharge men's day-conference is about restoring spiritual energy in Christian blokes by connecting them to the power supply of God's word, and rich fellowship across local churches. 
                <br/><br/>
                Typically there will be singing, some personal testimony, two talks by a quality speaker in the morning, a gourmet lunch, and an interactive session in the afternoon, finishing at 3pm. 
                <br/><br/>
                Recharge has enormous potential to bring glory to Jesus. When Christian men strive to follow Jesus, the benefit to their relationships at home and in the community is massive.
                <br/><br/>
                Looking forward to seeing you at Recharge in September!
              </p>

              <br />
              <br />

              <ReactPlayer
                className="ml-auto mr-auto text-center img-rounded img-responsive"
                url="https://www.youtube.com/watch?v=cWReJQ0CYDM"
                controls={true}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default About;
