/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Col, Container, Row } from "reactstrap";
import CustomAudioPlayer from '../../../components/Utils/CustomAudioPlayer';

function Recharge2018() {
  return (
    <>
      <div id="2018" className="section section-dark">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">2018</h1>
              <div className="card-avatar">
                <img
                  alt="Recharge 2018"
                  className="img-rounded img-responsive"
                  src={
                    require("assets/img/recharge_conference/talks/2018/recharge_2018.jpg")
                    .default
                  }
                />
              </div>
              <p className="p-larger description pb-2 pt-5">
                Our speaker in 2018 was Sam Swadling.<br/><br/>
                Sam is the Lead Pastor of Gospel Church Carrington which he planted with his wife Bethany and 3 children 4 years ago. 
                He is also the NSW Director for the Acts 29 Church Planting Network. 
                Sam believes that rather than being reactive to cultural views of masculinity, we need to be proactive in discipling men to be like Jesus.
              </p>

              <CustomAudioPlayer 
                title="Talk 1: Men of the Word"
                speaker="Sam Swadling"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/recharge/2018/Recharge_2018_Talk_1_Men_of_the_Word.mp3"
                filename="Recharge_2018_Talk_1_Men_of_the_Word.mp3"
              />
              <CustomAudioPlayer 
                title="Talk 2: Men of Courage"
                speaker="Sam Swadling"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/recharge/2018/Recharge_2018_Talk_2_Men_of_Courage.mp3"
                filename="Recharge_2018_Talk_2_Men_of_Courage.mp3"
              />
              <CustomAudioPlayer 
                title="Talk 3: Men of Compassion"
                speaker="Sam Swadling"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/recharge/2018/Recharge_2018_Talk_3_Men_of_Compassion.mp3"
                filename="Recharge_2018_Talk_3_Men_of_Compassion.mp3"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Recharge2018;
