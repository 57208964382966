/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Container, Row, Col } from "reactstrap";


function About() {
  return (
    <>
      <div id="about" className="section section-dark">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">About</h1>
              <p className="p-larger description pb-2">
                <strong>Renew</strong> is a ladies' conference in the Hunter Region of NSW, Australia where we learn about Jesus.
                <br/><br/>
                It is a refreshing time for women of all ages and stages in our walk with the Lord, whether we're learning about Him for the first time or have followed Him for many years.
                <br/><br/>
                Our focus is on Jesus so we listen to Him through the teaching of His word, we sing, we pray, and we hear the stories of others. 
                By doing this we endeavour to grow in love for our Lord and each other, seeking to spur one another to love in action.  
                As part of this, we endeavour to financially support local and international ministries by introducing them on the day.
                <br/><br/>
                So, we urge you to join us to be refreshed, encouraged and renewed this May!
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default About;
