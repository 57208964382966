/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Container, Row, Col } from "reactstrap";
import CustomAudioPlayer from '../../../components/Utils/CustomAudioPlayer';

function Renew2024() {
  return (
    <>
      <div id="2024" className="section section-dark">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">2024</h1>
              <div className="card-avatar">
                <img
                  alt="Renew 2024"
                  className="img-rounded img-responsive"
                  src={require("assets/img/renew_conference/2024/Postcard_Front_small.png").default}
                />
              </div>

              <p className="p-larger description pt-5 pb-2">
                With the noise of popular culture ringing in our ears, it's great to stay grounded in God's good plans as His people learn to live out His ideals in a relationally challenged world.
              </p>              
              <p className="p-larger description pt-5 pb-2">
                Our guest speaker was Dr Dani Treweek, who unpacked the Bible's teaching on God's intention for all our relationships. 
              </p>

              <CustomAudioPlayer 
                title="Talk 1"
                speaker="Dr Dani Treweek"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2024/Renew_2024-Talk_1.mp3"
                filename="Renew_2024-Talk_1.mp3"
              />     

              <CustomAudioPlayer 
                title="Talk 2"
                speaker="Dr Dani Treweek"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2024/Renew_2024-Talk_2.mp3"
                filename="Renew_2024-Talk_2.mp3"
              />            

              <CustomAudioPlayer 
                title="Talk 3"
                speaker="Dr Dani Treweek"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2024/Renew_2024-Talk_3.mp3"
                filename="Renew_2024-Talk_3.mp3"
              />           

              <CustomAudioPlayer 
                title="Q and A"
                speaker="Dr Dani Treweek and others"
                url="https://s3.ap-southeast-2.amazonaws.com/talks.hgm.org.au/renew/2024/Renew_2024-Q_and_A.mp3"
                filename="Renew_2024-Q_and_A.mp3"
              />            
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Renew2024;
