import RenewFooter from "components/Footers/RenewFooter.js";
import React from "react";
import { Helmet } from 'react-helmet';
import About from "views/renew-sections/About.js";
import Connect from "views/renew-sections/Connect.js";
import CurrentConference from "views/renew-sections/CurrentConference";
import RenewHeader from '../components/Headers/RenewHeader';
import RenewNavbar from '../components/Navbars/RenewNavbar';
import { domain, scheme } from './constants';
import Committee from './renew-sections/Committee';
import PastPics from './renew-sections/PastPics';

const title = 'The Renew Conference, for women in the Hunter Valley';
const pageUrl = `${scheme}${domain}/renew`; 

function Renew() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
      document.body.classList.add("index");
      return function cleanup() {
        document.body.classList.remove("index");
      };
    });
    return (
      <>
        <Helmet>
          <meta name='title' content={title} />
          <meta property="og:title" content={title} />
          <link rel='canonical' href={pageUrl} />
          <meta property="og:url" content={pageUrl} />
        </Helmet>
        <RenewNavbar />
        <RenewHeader />
        <div className="main">
          <CurrentConference /> 
          <PastPics />
          <About />
          <Committee />
          <Connect />
          <RenewFooter />
        </div>
      </>
    );
  }
  
  export default Renew;